<template>
  <div class="content">
<div class="return" v-if="clienId" @click="back"><img class="backImg"  src="@/assets/images/back.png" alt /></div>
      <!--列表-->
      <el-row class="mt30">
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
          >
            <ElTableColumn label="服务日期" prop="create_time" width='140'/>
            <ElTableColumn label="客户姓名" prop="customer_name" />
            <ElTableColumn label="性别" prop="sex" />
            <ElTableColumn label="年龄" prop="age" />
            <ElTableColumn label="门店" prop="shop_name" />
            <ElTableColumn label="市场" prop="market_name" />
            <ElTableColumn label="负责人" prop="nurse_name" />
            <ElTableColumn label="卡项名称" prop="name" />
            <ElTableColumn label="护理师1" prop="nurse_id_one_name" />
            <ElTableColumn label="护理师2" prop="nurse_id_two_name" />
            <ElTableColumn label="服务类型" prop="experience" />
            <ElTableColumn label="服务次数" prop="fw_num" />
            <ElTableColumn label="登陆人" prop="createName" />
            <ElTableColumn label="核销平台" prop="pt" />
            <!-- <ElTableColumn label="护理师1">
              <template slot-scope="{ row }">
                <div >{{row.nurse_id_one_name?row.nurse_id_one_name:'暂无'}}</div>
              </template>
            </ElTableColumn>
            <ElTableColumn label="护理师2">
              <template slot-scope="{ row }">
                <div >{{row.nurse_id_two_name?row.nurse_id_two_name:'暂无'}}</div>
              </template>
            </ElTableColumn> -->
            
          </el-table>
          
        </el-col>
      </el-row>
    </div>
</template>

<script>
import {readService} from '@/api/client';

export default {
  name: 'ServeDetail',
  
  data () {
    return {
      list: [], // 数据展示
      clienId:''
    }
  },

  mounted () {
    this.getList()
    this.clienId = this.$route.query.clienId
  },
  methods: {
    getList () {
      readService({id:this.$route.query.id}).then(res => {
        // console.log(res)
        this.list = res.service_log;
      })
    },
    // },
    // 返回
    back() {
      const query = {
        id: this.$route.query.clienId,
        detail:this.$route.query.detail,
        activeName: "sixth"
      };
      this.$router.push({ name: "客户详情", query });
    }

  }
}
</script>

<style lang="scss" scoped>
.mainColor {
  color: #2dc0a3;
}
.content {
  height: calc(100% - 56px);
  overflow-y: auto;
}
.return{
    position: fixed;
    right: 20px;
    top: 71px;
    z-index: 999;
    .backImg {
      margin-right: 14px ;
      width: 32px;
      height: 100%;
      cursor: pointer;
      vertical-align: middle;
  }
  }
.bgW {
  background: #fff;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 20px;
  border-bottom: 6px solid #eee;
}

.el-form-item {
  margin: 5px  40px  3px 0 ;
}
.accountCon{
    background: #fff;
    padding: 0px 10px 80px;
    .btnBox{
        display: flex;
        justify-content: space-between;
    }
}
</style>
